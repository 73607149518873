import React from "react";
import Layout from "../components/layout";
import { motion } from "framer-motion";

function LayoutContainer(props) {
  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <Layout {...props} />
    </motion.div>
  );
}

export default LayoutContainer;
